/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';
// @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;300;400;500;700&display=swap');
@import url(./assets/styles/fonts-custom.scss);

@font-face {
  font-family: "IBM Plex Sans";
  src: url("./assets/fonts/IBMPlexSans-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "IBM Plex Sans Light";
  src: url("./assets/fonts/IBMPlexSans-Light.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "IBM Plex Sans Medium";
  src: url("./assets/fonts/IBMPlexSans-Medium.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "IBM Plex Sans Bold";
  src: url("./assets/fonts/IBMPlexSans-Bold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Convergence Regular";
  src: url("./assets/fonts/Convergence-Regular.ttf") format("truetype");
  font-display: swap;
}

// Santander fonts

@font-face {
  font-family: "santander";
  src: url("./assets/fonts/santander/SANTANDERTEXT-LIGHT.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "santander";
  src: url("./assets/fonts/santander/SANTANDERTEXT-REGULAR.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "santander";
  src: url("./assets/fonts/santander/SANTANDERTEXT-SMBD.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "santander";
  src: url("./assets/fonts/santander/SANTANDERTEXT-BOLD.ttf") format("truetype");
  font-weight: bolder;
  font-display: swap;
}

// santander micro
@font-face {
  font-family: "santander micro";
  src: url("./assets/fonts/santander/SANTANDERMICROTEXTLT.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "santander micro";
  src: url("./assets/fonts/santander/SANTANDERMICROTEXT.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "santander micro";
  src: url("./assets/fonts/santander/SANTANDERMICROTEXTSMBD.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "santander micro";
  src: url("./assets/fonts/santander/SANTANDERMICROTEXTBD.ttf") format("truetype");
  font-weight: bolder;
  font-display: swap;
}

// santander headline
@font-face {
  font-family: "santander headline";
  src: url("./assets/fonts/santander/SANTANDERHEADLINELIGHT.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "santander headline";
  src: url("./assets/fonts/santander/SANTANDERHEADLINE-REGULAR.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "santander headline";
  src: url("./assets/fonts/santander/SANTANDERHEADLINE-BOLD_0.ttf") format("truetype");
  font-weight: bold;
  font-display: swap;
}

:root {
  // Theme Colors
  --primary-color: #377dff;
  --primary-light-color: #d7e5ff;
  --primary-dark-color: #2a5dbb;
  --secondary-color: #1e1429;
  --title-color: #0f2137;
  --font-color: #343d48;
  --background-color: #ffffff;
  --dark-gray-color: #3d3d3d;
  --medium-light-gray-color: #b2bac4;
  --gray-contrast-color: #84898f;
  --light-gray-color: #f9fafc;
  --white-color: #ffffff;
  --light-white: #f8f8f8;
  --black-color: #000000;
  --platinum-ipanema: #b7bec9;
  --background-light-gray: #f3f2f3;
  --border-light: #e5e5e5;
  --whatsapp-gray-color: #686c71;
  --success-color: #00ba88;
  --success-light-color: #83f1d4;
  --success-dark-color: #02644a;
  --warning-color: #ffda5f;
  --warning-background-color: #fcf3d5;
  --warning-light-color: #fcf3d5;
  --warning-dark-color: #a87c03;
  --danger-color: #ed2e7e;
  --danger-light-color: #ee5d99;
  --danger-dark-color: #921549;
  --background-span-color: #f5f6fa;
  --error-color: #ed2e7e;
  --disabled-gray-color: #84898f;
  --gray-support-color: #ebedf0;
  --success-color: #ccf1e7;
  --success-font-color: #00ba88;
  --santander-color: #cc0000;
  --disabled-santander-color: #ff4141;

  // Elements styles
  --radius-size: 20px;

  //Font family
  --font-family-default: "IBM Plex Sans", sans-serif;
  --font-family-Light: "IBM Plex Sans Light", sans-serif;
  --font-family-medium: "IBM Plex Sans Medium", sans-serif;
  --font-family-Bold: "IBM Plex Sans Bold", sans-serif;
  --font-family-Convergenge: "Convergence Regular", sans-serif;
  --font-family-Santander: "santander";
  --font-family-SantanderMicro: "santander micro";
  --font-family-SantanderHead: "santander headline";
}

html,
body {
  margin: 0;
  padding: 0;
  border: 0;
}

// Typos

* {
  font-family: var(--font-family-default);
  font-size: 16px;
  color: var(--font-color);
  outline: none;
}

h1 {
  font-family: var(--font-family-medium);
  font-size: 96px;

  @media (max-width: 992px) {
    font-size: 48px;
    line-height: 1em;
  }
}

h2 {
  font-family: var(--font-family-default);
  font-size: 48px;
}

h3 {
  font-family: var(--font-family-default);
  font-size: 34px;
}

h4 {
  font-family: var(--font-family-default);
  font-size: 24px;
}

h5 {
  font-family: var(--font-family-default);
  font-size: 20px;
}

h6 {
  font-family: var(--font-family-default);
  font-size: 18px;
}

strong {
  font-family: var(--font-family-Bold);
}

input {
  &::placeholder {
    font-family: var(--);
  }
}

ul {
  list-style: none;

  li a {
    color: var(--font-color);

    &:hover {
      text-decoration: none;
      color: var(--primary-color);
    }
  }
}

a {
  color: var(--font-color);

  &:hover {
    text-decoration: none;
    color: var(--primary-color);
  }
}

// App styles classes

.content-ipamena-center {
  max-width: 1440px;
  width: 100%;
  margin: 500px auto;
}

.content-ipamena-left {
  max-width: 1440px;
  width: 100%;
  margin: 231px 100px 0px 100px;

  @media (max-width: 768px) {
    margin: 200px 0px 0px 24px;
  }
}

// Imports

@import "assets/styles/buttons.scss";
@import "assets/styles/forms.scss";
@import "assets/styles/media-query.scss";
@import "assets/styles/tagline-dark-grey.scss";
@import "assets/styles/tagline-form-box-ligth-grey.scss";
@import "assets/styles/section-blue.scss";
@import "assets/styles/section-white.scss";
@import "assets/styles/withe-box.scss";
@import "assets/styles/form-group.scss";
@import "assets/styles/swiper.scss";
@import "assets/styles/switch.scss";

.hide-if-mobile {
  @media (max-width: 1200px) {
    display: none !important;
  }

  @media (width: 768px) and (height: 1024px) {
    display: none !important;
  }

  @media (width: 768px) and (height: 1024px) {
    display: none !important;
  }
}

.hide-if-mobile-small {
  @media (max-width: 540px) {
    display: none !important;
  }
}

.hide-if-desktop {
  display: none !important;

  @media (max-width: 1200px) {
    display: block !important;
  }

  @media (width: 768px) and (height: 1024px) {
    display: block !important;
  }

  @media (width: 768px) and (height: 1024px) {
    display: block !important;
  }
}

.no-spaces {
  margin: 0 !important;
  padding: 0 !important;
}

.sub-title-pages {
  font-size: 1.6rem;
}

span.swiper-pagination-bullet-active {
  width: 32px !important;
  border-radius: 8px !important;
  background: var(--primary-color);
}

span.swiper-pagination-bullet-active {
  width: 32px !important;
  border-radius: 8px !important;
  background: var(--primary-color);
}

.gr-modal-full .modal-dialog {
  min-width: 100%;
  margin: 0;
}

.gr-modal-full .modal-content {
  min-height: 100vh;
}

.toast {
  z-index: 99999;
  position: fixed;
  top: 0;
  right: 0;
  margin: 10px 20px;
  margin-top: 90px;
  padding: 5px 20px;
  border-radius: 50px;

  &.bg-danger {
    background-color: #ff7280 !important;
  }

  &.bg-danger,
  &.bg-success,
  &.bg-dark {
    .toast-body {
      color: var(--white-color);
      font-size: 15px;
    }
  }
}

.modal {
  // background-color: #ececec;
  z-index: 9999;

  .modal-content {
    border: none;
    border-radius: 20px;
  }
}

.toggled {
  background: #ffffff !important;
}

.logo-ipanema {
  max-width: 200px;
  height: 55%;
}

.feirao {
  cursor: pointer;
}

@media (max-width: 1209px) {
  .tutorial-content {
    margin: 30px 0;
    max-height: 310px;

    .swiper-container {
      .swiper-pagination-bullets {
        bottom: 80px !important;

        span {
          &.swiper-pagination-bullet-active {
            background: var(--white-color) !important;
          }
        }
      }
    }
  }
}

span.text-highlight {
  font-family: var(--font-family-Light);
  color: var(--primary-color);
  font-size: 18px;
  font-weight: bolder;
}

.bold {
  font-weight: 600;
}

.nav-link:hover,
.nav-link:focus {
  border-color: transparent !important;
}

.navbar-light .navbar-toggler {
  border-color: transparent !important;
  color: white;
}

.dropdown-menu {
  .ngb-dp-header {
    button:focus {
      box-shadow: none !important;
    }

    .ngb-dp-navigation-chevron {
      color: var(--font-color) !important;
    }
  }

  .ngb-dp-day:not(.disabled) {
    .text-muted {
      color: #212529 !important;
    }

    .outside {
      opacity: 1;
    }
  }
}

.code-expand-container {
  position: relative;
  height: 45px;
  width: 100%;
  overflow: hidden;
  text-align: left;
  -webkit-transition: height 0.01s;
  -moz-transition: height 0.01s;
  -ms-transition: height 0.01s;
  -o-transition: height 0.01s;
  transition: height 0.01s;

  &:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(154deg, transparent, white);
    background: linear-gradient(180deg, transparent, white);
  }

  .expand-text {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    align-content: flex-end;
    cursor: pointer;
    z-index: 99;

    &:hover {
      background-color: yellow;
    }
  }

  &.opened {
    height: 100%;

    &:after {
      background: transparent;
    }

    .expand-text {
      background: none;
      position: relative;
      bottom: 0 !important;

      small {
        position: relative;
        box-shadow: none;
        cursor: pointer;

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
}

.expand-button-container {
  text-align: center;
}

.expand-button {
  box-shadow: -8px 0px 20px 4px white;
  background: white;
  position: static;
  right: 20px;
  bottom: 0;
  cursor: pointer;
  font-weight: 600;

  &:hover {
    color: #377dff;
  }
}

.pix-code {
  margin-bottom: 0;
}

.legal-claim {
  background: #ffc10952;
  padding: 7px;
  border-radius: 30px;
  margin-right: 15px;

  >i {
    color: var(--warning);
  }
}

.default-link {
  text-decoration: underline;
  color: var(--primary-dark-color);
}

.bounce {
  -webkit-animation: ImgBounce1 0.8s ease-in-out infinite alternate;
  -moz-animation: ImgBounce1 0.8s ease-in-out infinite alternate;
  -o-animation: ImgBounce1 0.8s ease-in-out infinite alternate;
  animation: ImgBounce1 0.8s ease-in-out infinite alternate;
}

@keyframes ImgBounce1 {
  0% {
    -webkit-transform: translateY(0) rotate(0deg);
    -moz-transform: translateY(0) rotate(0deg);
    -ms-transform: translateY(0) rotate(0deg);
    -o-transform: translateY(0) rotate(0deg);
    transform: translateY(0) rotate(0deg);
  }

  100% {
    -webkit-transform: translateY(-10px) rotate(0deg);
    -moz-transform: translateY(-10px) rotate(0deg);
    -ms-transform: translateY(-10px) rotate(0deg);
    -o-transform: translateY(-10px) rotate(0deg);
    transform: translateY(-10px) rotate(0deg);
  }
}

#reputation-ra {
  margin-top: 18px;

  .ra-widget-reputation-holder {
    background-color: transparent !important;

    .ra-widget-reputation-content {
      background-color: white !important;
    }
  }
}


.btn-secondary {
  color: #0069d9;
  background-color: white;
  border-color: #0062cc;
  border-radius: 10px !important;
  width: 100%;
  box-shadow: none !important;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 22px;
    padding-right: 5px;
    stroke: #0062cc;
  }

  &:active,
  &:focus {
    color: #0062cc !important;
    border-color: #0062cc !important;
    width: 100% !important;
    background: white !important;

    svg,
    path {
      stroke: #0062cc;
    }
  }

  &:hover {
    color: #fff !important;
    background-color: #0062cc !important;

    svg,
    path {
      stroke: white;
    }
  }
}

.capitalize {
  text-transform: capitalize;
}