.form-group {
  height: 50px !important;
  margin: 20px 0px 50px 0px;
  position: relative;
  width: 100%;

  @media (max-width: 992px) {
    width: 100%;
  }

  @media (width: 768px) and (height: 1024px) {
    width: 100%;
  }
}

.form-group input,
.form-group label {
  cursor: text;
  font-size: 16px;
  position: absolute;
  transition: all 0.15s ease;
  width: 100%;
}

.form-group input {
  border-radius: 10px;
  height: 60px;
  padding: 20px 20px 0;
  border: 1px solid var(--black-color) !important;
  outline: none;
}

.form-group label {
  color: var(--black-color);
  font-weight: bolder;
  line-height: 60px;
  top: 0;
  padding: 0 20px;
  font-size: 16px;
  position: absolute;
  margin-top: -12px;
}

.form-group select {
  width: 100%;
  font-size: 16px;
  height: 60px;
  padding: 20px 20px 0;
  outline: none;
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid var(--black-color) !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #fff
    url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='18' height='18' viewBox='0 0 24 24'><path fill='grey' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>")
    no-repeat 98% 50%;
}

.form-group select ~ label {
  color: var(--black-color);
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  pointer-events: none;
  left: 17px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

textarea {
  border-radius: 20px;
  width: 100%;
  padding: 20px 20px;
  --font-color: var(--black-color);

  // @media (max-width: 992px) {
  //   width: 80%;
  // }

  @media (width: 768px) and (height: 1024px) {
    width: 100%;
  }
}

:root {
  --input-padding-x: 0.75rem;
  --input-padding-y: 0.75rem;
}

.floating-label {
  $placeholderColour: var(--font-color);
  position: relative;
  font-weight: bolder;
  font-family: var(--font-family);

  textarea {
    padding: var(--input-padding-y) var(--input-padding-x);
  }

  textarea ~ label {
    position: absolute;
    top: 10px;
    left: 37px;
    display: block;
    margin-bottom: 30px;
    line-height: 1.5;
    color: $placeholderColour;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    transition: all 0.1s ease-in-out;

    &:hover {
      cursor: text;
    }
  }

  textarea::-webkit-input-placeholder {
    color: transparent;
  }

  textarea:-moz-placeholder {
    color: transparent;
  }

  textarea:-ms-input-placeholder {
    color: transparent;
  }

  textarea::placeholder {
    color: transparent;
  }

  textarea:not(:placeholder-shown) {
    padding-top: 40px;
    padding-left: 20px;
    padding-bottom: 20px;
  }

  textarea:not(:placeholder-shown) ~ label {
    padding-top: calc(var(--input-padding-y) / 3);
    padding-bottom: calc(var(--input-padding-y) / 3);
    font-size: 18px;

    &:hover {
      cursor: default;
    }
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    label {
      display: none;
    }

    textarea:-ms-input-placeholder,
    input:-ms-input-placeholder {
      color: $placeholderColour;
    }
  }
}
