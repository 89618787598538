form {
  width: 100%;
  padding: 5px;

  .form-element {
    border: 1px solid #e5e5e5;
    padding: 10px 25px;
    border-radius: 15px;
    background-color: var(--white-color);

    label {
      display: block;
      font-family: var(--font-family-Bold);
      font-size: 13px;
    }

    input[type="text"],
    input[type="number"],
    input[type="password"],
    input[type="email"],
    input[type="date"],
    select,
    textarea {
      border: none;
      background-color: transparent;
      padding: 0;
      margin: 0;
      width: 100%;
    }
  }
}

.input-control-ipanema {
  background: #f8f8f8;
  box-shadow: 0 0 8px -5px #aaa;
  border-radius: 20px;
  padding: 10px 20px;
  display: flex;

  &.has-error {
    border: 1px solid red;
  }

  &.installments {
    select {
      font-size: 14px;
    }
  }

  .label-input {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  label {
    font-size: 16px;
    font-family: var(--font-family-Bold);
    width: 100%;
  }

  select,
  input {
    border: none;
    background-color: transparent;
    text-align: center;
  }

  .input-group-append {
    .btn-outline-secondary {
      border-radius: 0 !important;
      border: none;
      box-shadow: none;
      background: transparent;
    }
  }
}

.input-select-ipanema {
  background: #f8f8f8;
  box-shadow: 0 0 8px -5px #aaa;
  border-radius: 20px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &.has-error {
    border: 1px solid red;
  }

  label {
    font-size: 16px;
    font-family: var(--font-family-Bold);
    padding-right: 10px;
    margin: 0;
  }

  select,
  input {
    border: none;
    background-color: transparent;
    width: 50%;
  }
  .input-group-append {
    position: absolute;
    .btn-outline-secondary {
      position: relative;
      bottom: 26px;
      left: 147px;
      border-radius: 0 !important;
      border: none;
      box-shadow: none;
      background: transparent;
    }
  }

  button {
    outline: none !important;
    box-shadow: none !important;
  }
}
