.tagline-form-box-ligth-grey {
  background-color: var(--background-light-gray);
  border-radius: 100px;
  padding: 10px 40px;
  margin-top: -50px;

  @media (min-width: 320px) and (max-width: 480px) {
    border-radius: 10px;
  }
}
