.swiper-button-next {
  top: 375px !important;
  background: var(--primary-color);
  width: 42px;
  height: 42px;
  border-radius: 10px;

  &.swiper-button-disabled {
    background: #F8F8F8;

    &:after {
      color: var(--primary-color);
      font-size: 16px;
    }
  }

  &:after {
    color: white;
    font-size: 16px;
  }
}

.swiper-button-prev {
  width: 42px;
  height: 42px;
  left: calc(82% - 20px);
  top: 375px !important;

  @media (max-width: 992px) {
    left: calc(80% - 40px);
  }


  background: var(--primary-color);
  border-radius: 10px;

  &.swiper-button-disabled {
    background: #F8F8F8;

    &:after {
      color: var(--primary-color);
      font-size: 16px;
    }
  }

  &:after {
    color: white;
    font-size: 16px;
  }
}

.swiper-pagination {
  text-align: left;
}

.cards-trusted-by {
  background: #FFFFFF;
  border: 1px solid #DEDEDE;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 20px;
  min-height: 325px;

  h4 {
    font-size: 18px;
    line-height: 32px;
    font-weight: 400;
    margin-bottom: 45px;
    color: #5C5C5C;
  }

  h5 {
    font-size: 14px;
    line-height: 28px;
    font-family: var(--font-family-Bold);
    color: black;
  }
}

// .channel-swiper {
//   @media (max-width: 992px) {
//     .swiper-wrapper {
//       min-height: 482px;
//     }
//     .swiper-button-prev {
//       top: 455px!important;
//     }
//     .swiper-button-next {
//       top: 455px!important;
//     }
//   }
// }