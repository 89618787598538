button {
  background: none;
  border: none;
  padding: 0.7em 2em;
  font-size: 0.9rem;
  border-radius: var(--radius-size) !important;
  font-family: var(--font-family-default);

  &:hover {
    cursor: pointer;
  }

  &.outline {
    border: 2px solid var(--primary-color);
    color: var(--primary-color);

    &:disabled {
      color: #b6d0ff;
      border-color: #b6d0ff;
      cursor: not-allowed;
    }
  }

  &.primary {
    border: 2px solid var(--primary-color);
    color: var(--white-color);
    background-color: var(--primary-color);

    &:hover {
      background-color: #1d6cff;
      border-color: #1d6cff;
    }

    &:disabled {
      background-color: #b6d0ff;
      border-color: #b6d0ff;
      cursor: not-allowed;
    }
  }

  &.primary-santander {
    border-radius: 5px !important;
    border: 2px solid var(--santander-color);
    color: var(--white-color);
    background-color: var(--santander-color);

    &:hover {
      background-color: #e70f0f;
      border-color: #e70f0f;
    }

    &:disabled {
      background-color: var(--disabled-santander-color);
      border-color: var(--disabled-santander-color);
      cursor: not-allowed;
    }
  }

  &.light {
    border: 2px solid var(--light-gray-color);
    color: var(--font-color);
    background-color: var(--light-gray-color);

    &:hover {
      color: var(--primary-color);
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  &.link {
    color: var(--primary-color);
    padding: 7px 10px;
  }

  &.link-santander {
    color: var(--santander-color);
    padding: 7px 10px;
  }
}
