.white-box {
  background-color: var(--white-color);
  border-radius: var(--radius-size);
  padding: 50px;
  min-height: 300px;
  margin-bottom: 50px;

  p {
    color: var(--font-color) !important;
  }
}
