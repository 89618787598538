.tagline-dark-gray {
  background-color: var(--dark-gray-color);
  border-radius: var(--radius-size);
  padding: 20px 40px;
  margin-top: -50px;
  margin-bottom: 200px;

  h4 {
    color: var(--white-color);
  }
}
